import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { Modal } from 'react-bootstrap';
import SearchInput from 'components/SearchInput';
import { handleChange, loadMedia } from './redux/actions';
import { bytesToSize } from '../../../../components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faImages, faEye } from '@fortawesome/free-solid-svg-icons';
import useFilters from '../../../../../hooks/useFilters';
import AlertModal from 'components/AlertModal/AlertModal';
import Carousel from 'react-elastic-carousel';
import { toastr } from 'react-redux-toastr';
import CustomLabel from 'components/CustomLabel';
import { checkMediaFile } from 'v2/helpers/fileHelpers';

export const SalesPhotosList = () => {
  const dispatch = useDispatch();
  const {
    saleReducer: {
      isPhotosModalOpen,
      photosUploaded,
      saleStatusId,
      videosUploaded,
      photos,
      videos,
    },
  } = useSelector((state) => state);
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const [type, setType] = useState('');
  const [dataToRender, setDataToRender] = useState([]);

  const handleFilters = (photo) => {
    const querySearch = photo && photo.description ? [photo.description] : [];
    return filterByQuery(querySearch);
  };

  useEffect(() => {
    handleDataToRender();
  }, [photos, videos, photosUploaded, videosUploaded]);

  const handleDataToRender = () => {
    const mediaToRender = [
      ...photos,

      ...(photosUploaded ? photosUploaded : []),
      ...videos,

      ...(videosUploaded ? videosUploaded : []),
    ];
    if (isPhotosModalOpen && mediaToRender && mediaToRender.length > 0) {
      setDataToRender(mediaToRender.filter((media) => handleFilters(media)));
      dispatch(handleChange(false, 'isAddPhotosModalOpen'));
    } else {
      setDataToRender(mediaToRender);
      dispatch(handleChange(true, 'isAddPhotosModalOpen'));
    }
  };

  const handleAddNewPhoto = () => {
    if (saleStatusId === 5 || saleStatusId === 2)
      return toastr.warning(
        'Não é possível importar foto para com status finalizado ou cancelado.'
      );
    dispatch(handleChange(true, 'isAddPhotosModalOpen'));
  };

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <button
          className="btn btn-sucesso"
          onClick={handleAddNewPhoto}
          style={{
            marginRight: 10,
            height: '35px',
          }}
        >
          + Novos Arquivos
        </button>
        <SearchInput
          placeholder="Pesquisa por Descrição"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          containerStyle={{
            width: '70%',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
          }}
        >
          <span style={{ fontSize: '12px', marginBottom: '5px' }}>
            <strong>Tipo: </strong>
          </span>
          <select
            className="form-control foco-input"
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{ fontSize: '12px', height: '35px' }}
          >
            <option value="">Ambos</option>
            <option value="Foto">Fotos</option>
            <option value="Vídeo">Vídeos</option>
          </select>
        </div>
      </div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '12px',
        }}
        data={dataToRender.filter(handleFilters)}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 150,
            Cell: ({ value }) => {
              if (!value || isNaN(new Date(value).getTime())) {
                return new Date().toLocaleDateString();
              }
              return format(new Date(value), 'dd/MM/yyyy');
            },
          },
          {
            Header: 'Tipo',
            accessor: '',
            width: 150,
            Cell: (props) => {
              const mediaType = checkMediaFile(
                props.original?.name ?? props.original.originalname
              );
              return (
                <CustomLabel
                  text={mediaType}
                  backgroundColor={mediaType === 'Foto' ? '#428BCA' : '#5CB85C'}
                />
              );
            },
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            Cell: (props) => {
              return props.original && props.original.description
                ? props.original.description
                : 'Descrição disponível após conclusão da importação';
            },
          },
          {
            Header: 'Tamanho',
            accessor: 'size',
            width: 150,
            Cell: ({ value }) => bytesToSize(value),
          },
          {
            Header: 'Ações',
            width: 70,
            Cell: (props) => <TableActions row={props.original} />,
          },
        ]}
        defaultPageSize={10}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma venda encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

const TableActions = ({ row }) => {
  const [isConfirmDeleteImageModalOpen, setIsConfirmDeleteImageModalOpen] =
    useState(false);
  const [isImageCarouselOpen, setIsImageCarouselOpen] = useState(false);
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    saleReducer: {
      photos,
      videos,
      photosToDelete,
      photosUploaded,
      videosUploaded,
      saleStatusId,
    },
  } = useSelector((state) => state);

  const handleImages = () => {
    if (checkMediaFile(row.originalname ?? row.name) === 'Foto')
      return setIsImageCarouselOpen(true);
    setIsVideoPlayerOpen(true);
  };

  const handleDeleteImage = () => {
    if (row.upload) {
      dispatch(handleChange([...photosToDelete, row], 'photosToDelete'));
    }

    const isPhoto = checkMediaFile(row.originalname ?? row.name) === 'Foto';

    if (isPhoto) {
      dispatch([
        handleChange(
          photosUploaded.filter((photo) => photo.key !== row.key),
          'photosUploaded'
        ),
        handleChange(
          photos.filter((photo) => photo.id !== row.id),
          'photos'
        ),
      ]);
    } else {
      dispatch([
        handleChange(
          videosUploaded.filter((video) => video.key !== row.key),
          'videosUploaded'
        ),
        handleChange(
          videos.filter((video) => video.id !== row.id),
          'videos'
        ),
      ]);
    }
    setIsConfirmDeleteImageModalOpen(false);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <FontAwesomeIcon
          icon={faEye}
          style={{ color: '#1D7391', cursor: 'pointer' }}
          onClick={handleImages}
          size="12px"
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{
            color: '#D9534F',
            cursor:
              saleStatusId === 5 || saleStatusId === 2
                ? 'not-allowed'
                : 'pointer',
          }}
          onClick={() => {
            if (!(saleStatusId === 5 || saleStatusId === 2))
              setIsConfirmDeleteImageModalOpen(true);
          }}
          size="14px"
        />
      </div>

      <AlertModal
        show={isConfirmDeleteImageModalOpen}
        message="Deseja excluir essa foto? O processo é irreversível e a foto deverá ser importada novamente mais tarde."
        onCancel={() => setIsConfirmDeleteImageModalOpen(false)}
        onSubmit={handleDeleteImage}
        onHide={() => setIsConfirmDeleteImageModalOpen(false)}
      />

      {isImageCarouselOpen && (
        <Modal show animation onHide={() => setIsImageCarouselOpen(false)}>
          <Modal.Body>
            <Carousel
              itemsToShow={1}
              className="modal-add-items-carousel"
              showArrows={false}
              renderPagination={() => <div />}
            >
              <img
                src={row.location ?? (row.preview || row.link)}
                alt={row.name}
              />
            </Carousel>
          </Modal.Body>
        </Modal>
      )}
      {isVideoPlayerOpen && (
        <Modal show animation onHide={() => setIsVideoPlayerOpen(false)}>
          <Modal.Body>
            <div
              style={{
                height: '500px',
                maxHeight: 'calc(100vh - 120px)',
              }}
            >
              <video width="100%" height="100%" controls>
                <source
                  src={row.location ?? (row.preview || row.link)}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
